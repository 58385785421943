import { FunnelGuardService } from './services/funnel-guard/funnel-guard.service';
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from './services/auth-guard/auth-guard.service';
import { OpenModalGuardService } from './services/open-modal-guard/open-modal-guard.service';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./pages/home/home.module').then((m) => m.HomePageModule),
    canDeactivate: [OpenModalGuardService],
  },
  {
    path: 'perfil',
    loadChildren: () =>
      import('./pages/profile/profile.module').then((m) => m.ProfilePageModule),
    canActivate: [AuthGuardService],
    canDeactivate: [OpenModalGuardService],
  },
  {
    path: 'ofertas-laborales',
    loadChildren: () =>
      import('./pages/offers/offers.module').then((m) => m.OffersPageModule),
    canActivate: [AuthGuardService],
    canDeactivate: [OpenModalGuardService],
  },
  {
    path: 'publiofertas',
    loadChildren: () =>
      import('./pages/publiofertas/publiofertas.module').then(
        (m) => m.PubliofertasModule,
      ),
    canActivate: [AuthGuardService],
    canDeactivate: [OpenModalGuardService],
  },
  {
    path: 'findyou',
    loadChildren: () =>
      import('./pages/publiofertas/publiofertas.module').then(
        (m) => m.PubliofertasModule,
      ),
    canActivate: [AuthGuardService],
    canDeactivate: [OpenModalGuardService],
  },
  // postulaciones a ofertas colaborativas
  {
    path: 'postulacion/:postulation',
    loadChildren: () =>
      import('./pages/publiofertas/postulation/postulation.module').then(
        (m) => m.PostulationPageModule,
      ),
    canDeactivate: [OpenModalGuardService],
    data: { offer_type: 'colaborative' },
  },
  {
    path: 'minibio',
    loadChildren: () =>
      import('./pages/mini-bio/mini-bio.module').then((m) => m.MiniBioModule),
    canActivate: [AuthGuardService],
    canDeactivate: [OpenModalGuardService],
  },
  {
    path: 'publicar-oferta-colaborador',
    loadChildren: () =>
      import('./pages/funnel-colaborator/funnel-colaborator.module').then(
        (m) => m.FunnelColaboratorPageModule,
      ),
    canDeactivate: [OpenModalGuardService, FunnelGuardService],
  },
  {
    path: 'servicio-al-cliente',
    loadChildren: () =>
      import('./pages/client-service/client-service.module').then(
        (m) => m.ClientServicePageModule,
      ),
    canDeactivate: [OpenModalGuardService],
  },
  {
    path: 'oferta/:offerId',
    loadChildren: () =>
      import('./pages/postulations/postulations.module').then(
        (m) => m.PostulationsPageModule,
      ),
    canDeactivate: [OpenModalGuardService],
  },
  {
    path: 'oferta/:offerId/:source',
    loadChildren: () =>
      import('./pages/postulations/postulations.module').then(
        (m) => m.PostulationsPageModule,
      ),
    canDeactivate: [OpenModalGuardService],
  },
  {
    path: 'postulaciones',
    loadChildren: () =>
      import('./pages/my-postulations/my-postulations.module').then(
        (m) => m.MyPostulationsPageModule,
      ),
    canActivate: [AuthGuardService],
    canDeactivate: [OpenModalGuardService],
  },
  {
    path: 'plan-de-desarrollo/:performanceId',
    loadChildren: () =>
      import('./pages/pdi/pdi.module').then((m) => m.PdiPageModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'notificaciones',
    loadChildren: () =>
      import('./pages/notifications/notifications.module').then(
        (m) => m.NotificationsPageModule,
      ),
    canActivate: [AuthGuardService],
  },
  {
    path: 'performance/:performanceId',
    loadChildren: () =>
      import('./pages/performance/performance.module').then(
        (m) => m.PerformancePageModule,
      ),
  },
  {
    path: 'findme',
    loadChildren: () =>
      import('./pages/notifications/notifications.module').then(
        (m) => m.NotificationsPageModule,
      ),
    canActivate: [AuthGuardService],
  },
  {
    path: 'pdf-modal',
    loadChildren: () =>
      import('./pages/pdf-modal/pdf-modal.module').then(
        (m) => m.PdfModalPageModule,
      ),
  },
  {
    path: 'sales',
    loadChildren: () =>
      import('./pages/sales/sales.module').then((m) => m.SalesPageModule),
    canActivate: [AuthGuardService],
  },
  {
    path: 'admin',
    loadChildren: () =>
      import('./pages/admin/admin.module').then((m) => m.AdminPageModule),
    canActivate: [AuthGuardService],
  },
  {
    path: ':link',
    loadChildren: () =>
      import('./pages/mini-bio/mini-bio.module').then((m) => m.MiniBioModule),
  },
  {
    path: '**',
    redirectTo: '',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
