import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { ConfirmPage } from './pages/confirm/confirm.page';
import {
  AlertController,
  ModalController,
  PopoverController,
} from '@ionic/angular';
import { OpenModalGuardService } from './services/open-modal-guard/open-modal-guard.service';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { environment } from './../environments/environment';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { UserDataService } from './services/user-data/user-data.service';
import { ErrorMonitoringService } from './services/error-monitoring/error-monitoring.service';
import { HelperService } from './services/helper/helper.service';
import { SwUpdate } from '@angular/service-worker';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { NotificationsService } from './services/notifications/notifications.service';
import { UserProfileType } from './types';
import { PerformanceService } from './services/performance/performance.service';
import { MenuItemType } from './helperTypes';
import { CompaniesService } from './services/companies/companies.service';
import moment from 'moment-timezone';

declare var fbq: any;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  /** contiene los datos del usuario */
  user: UserProfileType = null;
  userSubscriptionState: Subscription;
  /** version de la app */
  appVersion = environment.version;
  /** tabs y subtabs para el menu personas */
  public userAppPages: MenuItemType[] = [
    {
      title: 'Inicio',
      icon: 'home-outline',
      click: () => {
        this.router.navigate(['']);
      },
      show: () => {
        return true;
      },
    },
    {
      title: 'Job City Premium',
      icon: 'star',
      click: () => {
        this.userDataService.openPremiumModal('menu-premium', this.user);
      },
      show: () => {
        return !this.user.premium;
      },
    },
    {
      title: 'Buscar ofertas',
      icon: 'search-outline',
      click: () => {
        this.router.navigate([`/ofertas-laborales`]);
      },
      show: () => {
        return this.user.minibio;
      },
    },
    {
      title: 'Mini Bio - CV digital',
      icon: 'document-text-outline',
      click: () => {
        this.router.navigate([`/minibio`]);
      },
      show: () => {
        return true;
      },
    },
    {
      title: 'Mis postulaciones',
      icon: 'file-tray-full-outline',
      click: async () => {
        // valida si es premium
        if (this.user.premium) {
          this.router.navigate([`/postulaciones`]);
        } else {
          const alert = await this.alertController.create({
            header: 'Funcionalidad Premium',
            message: `Activa este y más beneficios con Job City Premium`,
            cssClass: 'alert-modal',
            backdropDismiss: false,
            buttons: [
              {
                text: 'Ver beneficios',
                handler: async () => {
                  this.userDataService.openPremiumModal(
                    'menu-postulaciones',
                    this.user,
                  );
                },
              },
            ],
          });

          await alert.present();
        }
      },
      show: () => {
        return this.user.minibio;
      },
    },
    {
      title: 'Find Me - notificaciones',
      icon: 'notifications-outline',
      click: async () => {
        // valida si es premium o tiene find me activado
        if (
          this.user.premium ||
          (this.user.route && this.user.route.notifications)
        ) {
          this.router.navigate([`/findme`]);
        } else {
          const alert = await this.alertController.create({
            header: 'Funcionalidad Premium',
            message: `Activa este y más beneficios con Job City Premium`,
            cssClass: 'alert-modal',
            backdropDismiss: false,
            buttons: [
              {
                text: 'Ver beneficios',
                handler: async () => {
                  this.userDataService.openPremiumModal(
                    'menu-findme',
                    this.user,
                  );
                },
              },
            ],
          });

          await alert.present();
        }
      },
      show: () => {
        return this.user.minibio;
      },
    },
    {
      title: 'Test Performance',
      icon: 'bar-chart-outline',
      click: async () => {
        this.router.navigate([
          `/performance/${this.performanceUserRequest.docs[0].id}`,
        ]);
      },
      show: () => {
        // valida si tiene performance activado
        if (this.performanceUserRequest && !this.performanceUserRequest.empty) {
          return true;
        }
        return false;
      },
    },
  ];
  /** tabs y subtabs para el menu personas */
  public companyAppPages: MenuItemType[] = [
    {
      title: 'Inicio',
      icon: 'home-outline',
      click: () => {
        this.router.navigate(['/findyou']);
      },
      show: () => {
        return true;
      },
    },
    {
      title: 'Publicar oferta',
      icon: 'volume-high-outline',
      click: () => {
        if (this.companiesService.selectedCompany) {
          this.router.navigate([
            '/findyou/publicar-oferta',
            this.companiesService.selectedCompany.id,
          ]);
        }
      },
      show: () => {
        return (
          this.companiesService.selectedCompany &&
          this.companiesService.selectedCompany.status == 'validated'
        );
      },
    },
    {
      title: 'Mis ofertas',
      icon: 'document-text-outline',
      click: () => {
        if (this.companiesService.selectedCompany) {
          this.router.navigate([
            '/findyou/offers',
            this.companiesService.selectedCompany.id,
          ]);
        }
      },
      show: () => {
        return (
          this.companiesService.selectedCompany &&
          this.companiesService.selectedCompany.status == 'validated'
        );
      },
    },
    {
      title: 'Administrar usuarios',
      icon: 'people-outline',
      click: () => {
        if (this.companiesService.selectedCompany) {
          this.router.navigate([
            '/findyou/users/',
            this.companiesService.selectedCompany.id,
          ]);
        }
      },
      show: () => {
        return (
          this.companiesService.selectedCompany &&
          this.companiesService.selectedCompany.status == 'validated'
        );
      },
    },
    {
      title: 'Editar empresa',
      icon: 'brush-outline',
      click: () => {
        if (this.companiesService.selectedCompany) {
          this.router.navigate([
            '/findyou/empresas/',
            this.companiesService.selectedCompany.id,
          ]);
        }
      },
      show: () => {
        return (
          this.companiesService.selectedCompany &&
          this.companiesService.selectedCompany.status == 'validated'
        );
      },
    },
    {
      title: 'Base de datos',
      icon: 'folder-open-outline',
      click: () => {
        if (this.companiesService.selectedCompany) {
          this.router.navigate([
            '/findyou/minibios/',
            this.companiesService.selectedCompany.id,
          ]);
        }
      },
      show: () => {
        return (
          this.companiesService.selectedCompany &&
          this.companiesService.selectedCompany.status == 'validated'
        );
      },
    },
  ];
  /** contiene los tabs a cargar en el menu persona o empresa */
  public appPages: MenuItemType[] = [];
  /** contiene el evento de instalación */
  deferredPrompt: any;
  /** Indica si se debe mostrar el boton de instalar */
  showInstall = false;
  /** Indica si se debe mostrar el boton de notificaciones */
  showNotifications = false;
  /** almacena el valor del codigo de referido para ventas */
  referral: string;
  /** contiene el request para saber si el usuario tiene performance activo */
  performanceUserRequest;
  /** indica si ya registro la fecha de la ultima actividad del usuario */
  lastActivityUpdated = false;

  constructor(
    public userDataService: UserDataService,
    private errorMonitoringService: ErrorMonitoringService,
    public helperService: HelperService,
    private router: Router,
    private swUpdate: SwUpdate,
    private openModalGuardService: OpenModalGuardService,
    private modalController: ModalController,
    private afMessaging: AngularFireMessaging,
    public popoverController: PopoverController,
    private firebaseAnalytics: AngularFireAnalytics,
    private notificationsService: NotificationsService,
    private route: ActivatedRoute,
    private performanceService: PerformanceService,
    private companiesService: CompaniesService,
    private alertController: AlertController,
  ) {
    // Suscripción cambios de ruta si es production
    if (environment.production && !environment.test) {
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          // facebook
          fbq('track', 'PageView');
        }
      });
    }
    // agrega el valor del referido a local storage
    this.route.queryParams.subscribe((params) => {
      // obtiene el parametro referral
      this.referral = params['referral'];
      if (this.referral && typeof Storage !== 'undefined') {
        localStorage.referralCode = this.referral;
      }
    });
    // suscripción datos usuario
    this.userSubscriptionState =
      this.userDataService.userStateObservable.subscribe(
        async (user) => {
          this.user = user;
          if (this.user) {
            // valida el menu a cargar
            if (this.user.onboardingType) {
              this.appPages =
                this.user.onboardingType === 'company'
                  ? this.companyAppPages
                  : this.userAppPages;
            }
            this.errorMonitoringService.setUserContext(this.user);
            // guarda el codigo de referido en el usuario
            if (!this.user.referred && typeof Storage !== 'undefined') {
              const code = localStorage.referralCode;
              if (code) {
                // actualiza el perfil
                this.userDataService
                  .updateProfile(
                    {
                      referred: code,
                    },
                    this.user.uid,
                  )
                  .catch((error) => {
                    this.errorMonitoringService.sendError(
                      error,
                      'updateProfile - App Component',
                    );
                  });
              }
            }
            // muestra el boton de activar notifaciones
            this.notificationsService.notificationsStateObservable.subscribe(
              (value) => {
                this.showNotifications = value;
              },
            );
            // registra la fecha de la ultima actividad
            if (!this.lastActivityUpdated) {
              this.updateLastActivityDate();
            }
            // verifica si no tiene la propiedad de email validada
            if (!this.user.emailVerified) {
              this.updateMailValidated();
            }
            // valida si el usuario tiene performance
            this.performanceUserRequest = await this.performanceService
              .getPerformanceUserByEmail(this.user.email)
              .toPromise();
          } else {
            this.errorMonitoringService.setUserContext(null);
          }
        },
        (error) => {
          this.errorMonitoringService.sendError(error, 'getUser');
          this.helperService.presentMessageAlert(
            'Mensaje',
            'No pudimos obtener tu usuario, inténtalo de nuevo',
          );
        },
      );
    // valida si se debe mostrar el instalar
    // Detects if device is in standalone mode
    const navigator: any = window.navigator;
    const isInStandaloneMode = () =>
      'standalone' in navigator && navigator.standalone;
    // Checks if should display install popup notification in ios
    if (this.isIos() && !isInStandaloneMode()) {
      this.showInstall = true;
    }
    // Checks if should display install popup notification
    window.addEventListener('beforeinstallprompt', (e) => {
      // Stash the event so it can be triggered later.
      this.deferredPrompt = e;
      this.showInstall = true;
    });
  }

  async ngOnInit() {
    // quita el comportamiento de install app por default
    window.addEventListener('beforeinstallprompt', (e) => {
      // Prevent Chrome 76 and later from showing the mini-infobar
      e.preventDefault();
    });
    /* Verifica si hay nuevas versiones de la app y notifica al usuario para que recargue la página */
    if (this.swUpdate.isEnabled) {
      this.swUpdate.checkForUpdate().then((new_version) => {
        if (new_version) {
          this.openNewVersionModal();
        }
      });
    }
    // escucha los nuevos mensajes si el usuario esta activo en la app
    this.afMessaging.messages.subscribe(
      (message: any) => {
        this.helperService.presentMessageAlert(
          message.notification.title,
          message.notification.body,
        );
      },
      (error) => {
        this.errorMonitoringService.sendError(error, 'listenNotifications');
      },
    );
  }

  /** Detects if device is on iOS */
  isIos() {
    const userAgent = window.navigator.userAgent.toLowerCase();
    return /iphone|ipad|ipod/.test(userAgent);
  }

  /** determina si es administrador o no */
  isAdmin() {
    if (this.user && this.user.admin) {
      return true;
    }
    return false;
  }

  /** determina si es vendedor o no */
  isSeller() {
    if (this.user && this.user.referralCode) {
      return true;
    }
    return false;
  }

  /** abre el modal de nueva version disponible */
  async openNewVersionModal() {
    // indica que se debe bloquear/desbloquear la navegacion
    this.openModalGuardService.setModalOpen(true);
    // crea el modal con el chat
    const confirmModal = await this.modalController.create({
      component: ConfirmPage,
      cssClass: 'confirm-modal',
      componentProps: {
        image: 'assets/imgs/fireworks.png',
        title: '¡Nueva versión de la App!',
        subtitle: 'Continuamos trabajando para brindarte la mejor experiencia',
        buttons: [
          {
            title: 'Actualizar',
            path: '',
            type: 'execute',
            execute: () => {
              this.helperService.reloadPage();
            },
          },
        ],
      },
      backdropDismiss: false,
    });
    confirmModal.onDidDismiss().then(() => {
      // indica que se debe bloquear/desbloquear la navegacion
      this.openModalGuardService.setModalOpen(false);
    });
    // presenta el modal con el mapa
    return await confirmModal.present();
  }

  /** actualiza la fecha de la ultima actividad */
  updateLastActivityDate() {
    const updateData = {
      lastActivity: moment().format(),
    };
    this.userDataService
      .updateProfile(updateData, this.user.uid)
      .then(() => {
        this.lastActivityUpdated = true;
      })
      .catch((error) => {
        this.errorMonitoringService.sendError(error, 'updateLastActivityDate');
      });
  }

  /** actualiza el perfil para agregarle la propiedad emailVerified */
  updateMailValidated() {
    const updateData = {
      emailVerified: this.user.user.emailVerified,
    };
    this.userDataService
      .updateProfile(updateData, this.user.uid)
      .catch((error) => {
        this.errorMonitoringService.sendError(error, 'updateMailValidated');
      });
  }

  /** decide si muestra helper o modal de instalacion PWA, segun el sistema operativo */
  installPWA() {
    // registra el evento en GA
    this.firebaseAnalytics.logEvent('install');
    if (this.isIos()) {
      this.helperService.presentMessageAlert(
        'Instrucciones',
        'Da clic en el icono de compartir -> agregar a inicio',
      );
      return;
    }
    // Show the prompt
    this.deferredPrompt.prompt();
    // Wait for the user to respond to the prompt
    this.deferredPrompt.userChoice.then((choiceResult: any) => {
      if (choiceResult.outcome === 'accepted') {
        this.openInstalledModal();
      }
      this.deferredPrompt = null;
    });
  }

  /** muestra el modal para instalar PWA */
  async openInstalledModal() {
    // indica que se debe bloquear/desbloquear la navegacion
    this.openModalGuardService.setModalOpen(true);
    // crea el modal con el chat
    const confirmModal = await this.modalController.create({
      component: ConfirmPage,
      cssClass: 'confirm-modal',
      componentProps: {
        image: 'assets/imgs/fireworks.png',
        title: '¡App Instalada!',
        subtitle:
          'Ábrela ahora desde tu menú de aplicaciones o revisa tu menu de notificaciones',
        buttons: [],
      },
      backdropDismiss: false,
    });
    confirmModal.onDidDismiss().then(() => {
      // indica que se debe bloquear/desbloquear la navegacion
      this.openModalGuardService.setModalOpen(false);
    });
    // presenta el modal con el mapa
    return await confirmModal.present();
  }

  /** abre el modal de activar notificaciones */
  activateNotifications() {
    // registra el evento en GA
    this.firebaseAnalytics.logEvent('activateMessages');
    this.notificationsService.openNotificationsModal(this.user);
  }

  /** abre un link */
  openLink(type: string, event: string) {
    this.firebaseAnalytics.logEvent(event);
    window.open(
      type === 'politics' ? environment.politicsLink : environment.termsLink,
      'blank',
    );
  }

  /** cambia el rol del usuario */
  changeUserRole() {
    this.helperService.presentLoading();
    const newRole =
      this.user.onboardingType === 'company' ? 'person' : 'company';
    this.userDataService
      .updateProfile(
        {
          onboardingType: newRole,
        },
        this.user.uid,
      )
      .then(() => {
        this.helperService.dismissLoading();
        if (newRole === 'company') {
          this.router.navigate(['/findyou'], {
            replaceUrl: true,
          });
        } else {
          this.router.navigate([''], {
            replaceUrl: true,
          });
        }
      })
      .catch((error) => {
        this.helperService.dismissLoading();
        this.helperService.presentMessageAlert(
          'Error',
          'No pudimos cambiar el rol, inténtalo de nuevo',
        );
        this.errorMonitoringService.sendError(error, 'changeUserRole');
      });
  }

  // on destroy
  ngOnDestroy(): void {
    if (this.userSubscriptionState) {
      this.userSubscriptionState.unsubscribe();
    }
  }
}
